import { FC, useState } from 'react';
import { Circle } from 'react-leaflet';
import { LatLngTuple } from 'leaflet';
import InfoPopup from './InfoPopup';

interface ICircleArea {
  latitude: number;
  longitude: number;
  name: string;
  description: string;
  radius: number;
  id: number;
  lifeTime: number;
  owner: boolean;
};

const CircleArea: FC<ICircleArea> = ({ latitude, longitude, radius, name, description, id, lifeTime, owner }) => {
  const [popupPosition, setPopupPosition] = useState<LatLngTuple | null>(null);

  const onClick = (event: any) => {
    setPopupPosition([event.latlng.lat, event.latlng.lng])
  };

  return (
    <>
      <Circle
        eventHandlers={{
          click: onClick
        }}
        center={{ lat: latitude, lng: longitude }}
        radius={radius}
        pathOptions={{
          color: `${lifeTime > 0 ? '#CF6679' : '#808080'}`,
        }}
      >
      </Circle>
      <InfoPopup
        position={popupPosition}
        title={name}
        description={description}
        id={id}
        owner={owner}
        areaLifeTime={lifeTime}
      />
    </>
  )
};

export default CircleArea;
