import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { API_URL } from '../constants';
import useApiRequest from '../hooks/useApiRequest';
import { useAuth } from '../contexts/auth';
import { useData } from '../contexts/data';

interface IAreaControlForm {
  id: number;
  areaLifeTime: number;
};

interface FormData {
  lifeTime: number;
}

const AreaControlForm: FC<IAreaControlForm> = ({ id, areaLifeTime }) => {
  const { getToken } = useAuth();
  const { fetchData: updateMapItems, dataLoading } = useData();
  const { register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      lifeTime: 15
    }
  });

  const { makeRequest: updateArea, data: updatedData, loading: updateLoading } = useApiRequest<any>(
    {
      url: `${API_URL}/areas/${id}`,
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/merge-patch+json',
      }
    },
    true
  );

  const { makeRequest: deleteArea, code: deletedCode, loading: deleteLoading } = useApiRequest<any>(
    {
      url: `${API_URL}/areas/${id}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    },
    true
  );

  useEffect(() => {
    if (updatedData) {
      updateMapItems();
    }
  }, [updatedData, updateMapItems]);

  useEffect(() => {
    if (deletedCode) {
      updateMapItems();
    }
  }, [deletedCode, updateMapItems]);

  const onActivateSubmit: SubmitHandler<FormData> = (data) => {
    updateArea({ body: { lifeTime: data.lifeTime * 60 } });
  };

  const onDeactivateSubmit: SubmitHandler<FormData> = () => {
    updateArea({ body: { lifeTime: 0 } });
  };

  const onDeleteSubmit: SubmitHandler<FormData> = () => {
    deleteArea({ body: {} });
  };

  return (
    <form className='area-c-form'>
      <div className='area-c-form__line'>
        {areaLifeTime > 0 ?
          <button
            className='area-c-form__btn' onClick={handleSubmit(onDeactivateSubmit)}
            disabled={updateLoading || dataLoading || deleteLoading}>
            Деактивувати
          </button>
          :
          <div>
            <button
              className='area-c-form__btn area-c-form__btn_activate'
              onClick={handleSubmit(onActivateSubmit)}
              disabled={updateLoading || dataLoading || deleteLoading}>
              Активувати
            </button>
            на
            <input
              id="lifeTime"
              className='area-c-form__control'
              type="number"
              {...register('lifeTime', { required: 'Введіть кількість хвилин', valueAsNumber: true })}
            />
            хв.
          </div>
        }
      </div>

      <div className='area-c-form__line'>
        <button
          className='area-c-form__btn area-c-form__btn_delete' onClick={handleSubmit(onDeleteSubmit)}
          disabled={updateLoading || dataLoading || deleteLoading}>
          Видалити
        </button>
      </div>
    </form>
  )
}

export default AreaControlForm;
