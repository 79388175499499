import { FC } from "react";
import LoginForm from "./LoginForm";


const Login: FC = () =>
  <div className="login__container">
    <div className="login__holder">
      <h2 className="login__title">Авторизація</h2>
      <LoginForm />
    </div>
  </div>;

export default Login;
