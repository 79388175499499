import { FC, useRef } from 'react';
import { Popup, useMapEvent } from 'react-leaflet';
import { useCoordinateBuffer } from '../contexts/coordinateBuffer';
import { LatLngTuple } from 'leaflet';


const ClickMapPopup: FC = () => {
  const { coordinates, updateCoordinates } = useCoordinateBuffer();
  const currentPopup = useRef(null);

  useMapEvent('dblclick', (event) => {
    updateCoordinates([event.latlng.lat, event.latlng.lng])
  });

  useMapEvent('popupclose', (event) => {
    if (event.popup === currentPopup.current) {
      updateCoordinates(null)
    }
  });

  return (
    coordinates && typeof coordinates[0] === 'number' ? <Popup ref={currentPopup} position={coordinates as LatLngTuple} closeOnClick>
      <div className='info-tooltip'>
        <div className='info-tooltip__description'>
          <div>Широта: {coordinates[0]}</div>
          <div>Довгота: {coordinates[1]}</div>
        </div>
      </div>
    </Popup> : null
  );
};

export default ClickMapPopup;
