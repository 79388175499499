import { FC } from 'react';
import './App.scss';
import { AuthProvider } from './contexts/auth';
import AppContent from './components/AppContent';
import { BrowserRouter } from 'react-router-dom';
import { CoordinateBufferProvider } from './contexts/coordinateBuffer';
import { MapConnectorProvider } from './contexts/mapConnector';


const App: FC = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <MapConnectorProvider>
          <CoordinateBufferProvider>
            <AppContent />
          </CoordinateBufferProvider>
        </MapConnectorProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
