import { FC, useCallback, useEffect, useRef } from 'react';
import { FeatureGroup } from 'react-leaflet';
import { useCoordinateBuffer } from '../contexts/coordinateBuffer';
import { EditControl } from 'react-leaflet-draw';
import { LatLng } from 'leaflet';


const Draw: FC = () => {
  const { coordinates, updateCoordinates } = useCoordinateBuffer();
  const drawItems = useRef<any>(null);

  useEffect(() => {
    if (!coordinates || coordinates.length < 3) {
      drawItems.current?.clearLayers();
    }
  }, [coordinates])

  const onDrawStart = useCallback(() => {
    drawItems.current?.clearLayers();
  }, [drawItems]);

  const onCreated = useCallback((e: any) => {
    const coords = e.layer.getLatLngs()[0].map((item: LatLng) => [item.lat, item.lng]);
    updateCoordinates(coords);
  }, [updateCoordinates]);

  return (
    <FeatureGroup ref={drawItems}>
      <EditControl
        position='bottomright'
        onDrawStart={onDrawStart}
        onCreated={onCreated}
        draw={{
          circle: false,
          circlemarker: false,
          marker: false,
          polyline: false,
        }}
        edit={{ edit: false, remove: false }}
      />
    </FeatureGroup>
  );
};

export default Draw;
