import { FC, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { MarkerType } from './AddArea';
import { useAuth } from '../contexts/auth';
import useApiRequest from '../hooks/useApiRequest';
import { API_URL } from '../constants';
import { useCoordinateBuffer } from '../contexts/coordinateBuffer';
import { useData } from '../contexts/data';
import { useNavigate } from 'react-router-dom';

interface FormData {
  type: MarkerType;
  latitude: string;
  longitude: string;
  name: string;
  description: string;
  azimuth: number;
  status: number;
  ours: string;
  groups: string[];
  lifeTime: number;
}

interface FormResult {
  id: number;
  latitude: number;
  longitude: number;
  type: string;
  description: string;
  name: string;
  azimuth: number;
  ours: number;
  createdAt: string;
};

const AddDrone: FC = () => {
  const { getGroups, getToken } = useAuth();
  const { coordinates, updateCoordinates } = useCoordinateBuffer();
  const { fetchData } = useData();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm<FormData>({
    defaultValues: {
      type: MarkerType.Multirotor,
      latitude: '',
      longitude: '',
      ours: '1',
      azimuth: 0,
      name: '',
      description: '',
      lifeTime: 1
    }
  });

  const { makeRequest, data, error, loading } = useApiRequest<FormResult>(
    {
      url: `${API_URL}/points`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    },
    true
  );

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const cleanedData = { ...data, ours: parseInt(data.ours), lifeTime: data.lifeTime * 60 }
    makeRequest({ body: cleanedData })
  };

  useEffect(() => {
    if (data) {
      reset();
      fetchData();
      navigate('/');
    }
  }, [data, reset, fetchData, navigate]);

  useEffect(() => {
    if (coordinates) {
      setValue('latitude', `${coordinates[0]}`);
      setValue('longitude', `${coordinates[1]}`);
      updateCoordinates(null);
    }
  }, [coordinates, setValue, updateCoordinates]);

  return (
    <form className='add-form' onSubmit={handleSubmit(onSubmit)}>
      {error &&
        <div className='add-form__error add-form__error_top'>
          Помилка додавання. Перевірте правильність данних.
        </div>
      }
      <div className='add-form__f-holder'>
        <label htmlFor='resourceType' className='add-form__label'>Тип: </label>
        <div>
          <label className='add-form__radio'>
            <input type="radio" value={MarkerType.Multirotor} {...register('type', { required: 'Виберіть тип ресурсу' })} />
            Коптер
          </label>
          <label className='add-form__radio'>
            <input type="radio" value={MarkerType.Wing} {...register('type', { required: 'Виберіть тип ресурсу' })} />
            Крило
          </label>
        </div>
        {errors.type && <p className='add-form__error'>{errors.type.message}</p>}
      </div>

      <div className='add-form__f-holder'>
        <label htmlFor='ours' className='add-form__label'>Наш: </label>
        <div>
          <label className='add-form__radio'>
            <input type="radio" value={'1'} {...register('ours')} />
            Так
          </label>
          <label className='add-form__radio'>
            <input type="radio" value={'0'} {...register('ours')} />
            Ні
          </label>
        </div>
      </div>

      <div className='add-form__f-holder'>
        <label htmlFor='name' className='add-form__label'>Назва:</label>
        <input type="text" className='add-form__control' {...register('name', { required: 'Введіть назву' })} />
        {errors.name && <p className='add-form__error'>{errors.name.message}</p>}
      </div>

      <div className='add-form__f-holder'>
        <label htmlFor='description' className='add-form__label'>Опис:</label>
        <textarea className='add-form__control add-form__control_area' {...register('description')} />
        {errors.description && <p className='add-form__error'>{errors.description.message}</p>}
      </div>

      <div className='add-form__f-holder'>
        <label className='add-form__label'>Доступно для: </label>
        <select
          multiple
          id="groups"
          className='add-form__control add-form__control_multi'
          {...register('groups', { required: 'Оберіть хоча б одну групу' })}
        >
          {getGroups().map(group => <option key={group.name} value={`/api/groups/${group.id}`}>{group.name}</option>)}
        </select>
        {errors.groups && <p className='add-form__error'>{errors.groups.message}</p>}
      </div>

      <div className='add-form__grid'>
        <div className='add-form__grid-item'>
          <label htmlFor='latitude' className='add-form__label'>Широта: </label>
          <input
            type="number"
            className='add-form__control'
            step="0.0000000000000001"
            {...register('latitude', { required: 'Введіть широту' })}
          />
          {errors.latitude && <p className='add-form__error'>{errors.latitude.message}</p>}
        </div>

        <div className='add-form__grid-item'>
          <label htmlFor='longitude' className='add-form__label'>Довгота: </label>
          <input
            type="number"
            className='add-form__control'
            step="0.0000000000000001"
            {...register('longitude', { required: 'Введіть довготу' })}
          />
          {errors.longitude && <p className='add-form__error'>{errors.longitude.message}</p>}
        </div>
      </div>

      <div className='add-form__f-holder'>
        <label htmlFor='azimuth' className='add-form__label'>Азимут: </label>
        <input
          type="number"
          className='add-form__control'
          {...register('azimuth',
            { required: 'Введіть азимут', valueAsNumber: true })}
        />
        {errors.azimuth && <p className='add-form__error'>{errors.azimuth.message}</p>}
      </div>

      <div className='add-form__f-holder'>
        <label htmlFor='lifeTime' className='add-form__label'>Доступно протягом, хвилин: </label>
        <input
          id="lifeTime"
          className='add-form__control'
          type="number"
          {...register('lifeTime', { required: 'Введіть кількість хвилин', valueAsNumber: true })}
        />
        {errors.lifeTime && <p className='add-form__error'>{errors.lifeTime.message}</p>}
      </div>

      <div className='add-form__s-holder'>
        <button className='add-form__submit' type="submit" disabled={loading || Object.keys(errors).length > 0}>
          Зберегти
        </button>
      </div>
    </form>
  );
};

export default AddDrone;
