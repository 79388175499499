import { FC, useEffect } from 'react';
import { useForm, useFieldArray, SubmitHandler } from 'react-hook-form';
import { API_URL } from '../constants';
import { useAuth } from '../contexts/auth';
import useApiRequest from '../hooks/useApiRequest';
import { useCoordinateBuffer } from '../contexts/coordinateBuffer';
import { PointTuple } from 'leaflet';
import { useData } from '../contexts/data';
import { useNavigate } from 'react-router-dom';

export enum MarkerType {
  Circle = 'circle',
  Polygon = 'polygon',
  Wing = 'wing',
  Multirotor = 'multirotor',
}

interface FormData {
  type: MarkerType;
  name: string;
  groups: string[];
  lifeTime: number;
  latitude?: string;
  longitude?: string;
  radius?: number;
  coordinates?: string[][];
}


const AddArea: FC = () => {
  const { getGroups, getToken } = useAuth();
  const { fetchData } = useData();
  const navigate = useNavigate();
  const { coordinates, updateCoordinates } = useCoordinateBuffer();
  const { register, unregister, handleSubmit, control, watch, formState: { errors }, reset, setValue } = useForm<FormData>({
    defaultValues: {
      type: MarkerType.Circle,
      latitude: '',
      longitude: '',
      coordinates: [
        ['', ''],
        ['', ''],
        ['', ''],
      ],
      lifeTime: 15
    }
  });

  const { makeRequest, data, error, loading } = useApiRequest<any>(
    {
      url: `${API_URL}/areas`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    },
    true
  );

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'coordinates',
  });

  const onSubmit: SubmitHandler<FormData> = (data) => {
    if (data.type === MarkerType.Polygon) {
      data.coordinates?.push(data.coordinates?.[0])
    }
    data.lifeTime = data.lifeTime * 60;
    makeRequest({ body: data })
  };

  const selectedType = watch('type');

  useEffect(() => {
    if (selectedType === MarkerType.Polygon) {
      register("coordinates");
      unregister("latitude");
      unregister("longitude");
      unregister("radius");
    } else {
      unregister("coordinates");
      register("latitude");
      register("longitude");
      register("radius");
    }
  }, [register, unregister, selectedType]);

  useEffect(() => {
    if (data) {
      reset();
      fetchData();
      navigate('/');
    }
  }, [data, reset, fetchData, navigate]);

  useEffect(() => {
    if (coordinates) {
      if (typeof coordinates[0] === 'number') {
        setValue('latitude', `${coordinates[0]}`);
        setValue('longitude', `${coordinates[1]}`);
      } else {
        setValue('type', MarkerType.Polygon);
        const coords = coordinates as PointTuple[];
        coords.forEach((coord, index) => {
          update(index, [coord[0].toString(), coord[1].toString()]);
        })
      }
      updateCoordinates(null);
    }
  }, [coordinates, setValue, updateCoordinates, update]);

  return (
    <form className='add-form' onSubmit={handleSubmit(onSubmit)}>
      {error &&
        <div className='add-form__error add-form__error_top'>
          Помилка додавання. Перевірте правильність данних.
        </div>
      }
      <div className='add-form__f-holder'>
        <label className='add-form__radio'>
          <input
            type="radio"
            value={MarkerType.Circle}
            {...register('type', { required: 'Оберіть тип зони' })}
          />
          Коло
        </label>
        <label className='add-form__radio'>
          <input
            type="radio"
            value={MarkerType.Polygon}
            {...register('type', { required: 'Оберіть тип зони' })}
          />
          Полігон
        </label>
      </div>

      <div className='add-form__f-holder'>
        <label htmlFor='name' className='add-form__label'>Назва: </label>
        <input
          id="name"
          className='add-form__control'
          type="text"
          {...register('name', { required: 'Введіть назву' })}
        />
        {errors.name && <p className='add-form__error'>{errors.name.message}</p>}
      </div>

      <div className='add-form__f-holder'>
        <label className='add-form__label'>Доступно для: </label>
        <select
          multiple
          id="groups"
          className='add-form__control add-form__control_multi'
          {...register('groups', { required: 'Оберіть хоча б одну групу' })}
        >
          {getGroups().map(group => <option key={group.name} value={`/api/groups/${group.id}`}>{group.name}</option>)}
        </select>
        {errors.groups && <p className='add-form__error'>{errors.groups.message}</p>}
      </div>

      <div className='add-form__f-holder'>
        <label htmlFor='lifeTime' className='add-form__label'>Доступно протягом, хвилин: </label>
        <input
          id="lifeTime"
          className='add-form__control'
          type="number"
          {...register('lifeTime', { required: 'Введіть кількість хвилин', valueAsNumber: true })}
        />
        {errors.lifeTime && <p className='add-form__error'>{errors.lifeTime.message}</p>}
      </div>

      {selectedType === 'circle' && (
        <>
          <div className='add-form__grid'>
            <div className='add-form__grid-item'>
              <label htmlFor='latitude' className='add-form__label'>Широта: </label>
              <input
                type="number"
                className='add-form__control'
                step="0.0000000000000001"
                {...register('latitude', { required: 'Введіть широту' })}
              />
              {errors.latitude && <p className='add-form__error'>{errors.latitude.message}</p>}
            </div>

            <div className='add-form__grid-item'>
              <label htmlFor='longitude' className='add-form__label'>Довгота: </label>
              <input
                type="number"
                className='add-form__control'
                step="0.0000000000000001"
                {...register('longitude', { required: 'Введіть довготу' })}
              />
              {errors.longitude && <p className='add-form__error'>{errors.longitude.message}</p>}
            </div>
          </div>
          <div className='add-form__f-holder'>
            <label htmlFor='radius' className='add-form__label'>Радіус, метрів:</label>
            <input className='add-form__control'
              id='radius'
              type="number"
              {...register('radius', {
                required: 'Введіть радіус',
                min: { value: 1, message: 'Радіус повинен бути більше 0' },
                valueAsNumber: true
              })}
            />
            {errors.radius && <p className='add-form__error'>{errors.radius.message}</p>}
          </div>
        </>
      )}

      {selectedType === 'polygon' && (
        <>
          {fields.map((field, index) => (
            <div key={field.id} className='add-form__grid'>
              <div className='add-form__grid-item'>
                <label htmlFor={`latitude-${index + 1}`} className='add-form__label'>Широта точки {index + 1}:</label>
                <input
                  className='add-form__control'
                  id={`latitude-${index + 1}`}
                  type="number"
                  step="0.0000000000000001"
                  {...register(`coordinates.${index}.0` as const, { required: 'Введіть широту' })}
                />
                {errors?.coordinates?.[index]?.[0] && (
                  <p className='add-form__error'>{errors?.coordinates?.[index]?.[0]?.message}</p>
                )}
              </div>
              <div className='add-form__grid-item'>
                <label htmlFor={`longitude-${index + 1}`} className='add-form__label'>Довгота точки {index + 1}:</label>
                <input
                  className='add-form__control'
                  id={`longitude-${index + 1}`}
                  type="number"
                  step="0.0000000000000001"
                  {...register(`coordinates.${index}.1` as const, { required: 'Введіть довготу' })}
                />
                {errors?.coordinates?.[index]?.[1] && (
                  <p className='add-form__error'>{errors?.coordinates?.[index]?.[1]?.message}</p>
                )}
              </div>
              {index > 2 && <div className='add-form__del' onClick={() => remove(index)}>X</div>}
            </div>
          ))}
          {errors.coordinates && <p>{errors.coordinates.message}</p>}
          <div className='add-form__s-holder'>
            <button className='add-form__submit' type="button" onClick={() => append([''])}>
              Додати точку
            </button>
          </div>
        </>
      )}

      <div className='add-form__s-holder'>
        <button className='add-form__submit' type="submit" disabled={loading || Object.keys(errors).length > 0}>
          Зберегти
        </button>
      </div>
    </form>
  );
};

export default AddArea;
