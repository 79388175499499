import { FC, createContext, useContext, useState, ReactNode, useCallback } from 'react';
import { Map } from 'leaflet';

interface MapConnectorContextProps {
  connectMap: (map: Map) => void;
  map: Map | null;
}

const MapConnectorContext = createContext<MapConnectorContextProps | undefined>(undefined);

interface MapConnectorProviderProps {
  children: ReactNode;
}

export const useMapConnector = (): MapConnectorContextProps => {
  const context = useContext(MapConnectorContext);
  if (!context) {
    throw new Error("useMapConnector should be used within MapConnectorProvider");
  }
  return context;
};

export const MapConnectorProvider: FC<MapConnectorProviderProps> = ({ children }) => {
  const [map, setMap] = useState<Map | null>(null);

  const connectMap = useCallback((map: Map) => {
    setMap(map);
  }, []);


  const contextValue: MapConnectorContextProps = {
    connectMap,
    map,
  };

  return <MapConnectorContext.Provider value={contextValue}>{children}</MapConnectorContext.Provider>
}
