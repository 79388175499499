import { divIcon } from "leaflet";

const createIcon = (color: string = '#CF6679', azimuth: number = 0) => divIcon({
	html: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="${color}" style="transform: rotate(${azimuth}deg);" version="1.1" id="Layer_1" viewBox="0 0 512 512" xml:space="preserve">
			<polygon points="512,255.709 512,176.558 308.253,176.558 308.253,47.192 256,1.172 203.747,47.193 203.747,176.559 0,176.559     0,255.711 203.747,279.93 203.747,349.021 163.878,349.021 163.878,382.413 208.79,382.413 232.074,445.747 194.134,445.747     194.134,479.138 244.35,479.138 256,510.828 267.65,479.137 317.866,479.137 317.866,445.746 279.926,445.746 303.21,382.412     348.122,382.412 348.122,349.02 308.253,349.02 308.253,279.93   "/>
	</svg>`,
	className: "user-marker",
	iconSize: [30, 30],
	iconAnchor: [15, 30],
});

export default createIcon;
