import { FC, JSX } from "react";
import { Link } from "react-router-dom";

declare interface IFormPage {
  children: JSX.Element
}


const FormPage: FC<IFormPage> = ({ children }) =>
  <div className='form-page'>
    <Link className="form-page__back" to="/">До карти</Link>
    <div className='form-page__form-holder'>
      {children}
    </div>
  </div>;

export default FormPage;
