import { useState, useEffect, FC, useRef } from "react";
import { LatLngTuple } from "leaflet";
import { Marker, useMap, Tooltip, useMapEvent } from "react-leaflet";
import user from "../markers/user";
import { MarkerType } from "./AddArea";
import ClickMapPopup from "./ClickMapPopup";
import DroneMarker from "./DroneMarker";
import { useData } from "../contexts/data";
import { useMapConnector } from "../contexts/mapConnector";
import Draw from "./Draw";
import CircleArea from "./CircleArea";
import PolygonArea from "./PolygonArea";

declare interface IMap {
  initCoords: LatLngTuple
}

const Map: FC<IMap> = ({ initCoords }) => {
  const map = useMap();
  const { map: connectedMap, connectMap } = useMapConnector();
  let timeoutId: NodeJS.Timeout | null = null;
  const [position, setPosition] = useState<LatLngTuple>(initCoords);
  const [prevZoom, setPrevZoom] = useState(map.getZoom());
  const intervalID = useRef<ReturnType<typeof setInterval> | null>(null);
  const [locationDetected, setLocationDetected] = useState(false);
  const { fetchData, data } = useData();

  useEffect(() => {
    connectMap(map);
  }, [map, connectMap])


  useEffect(() => {
    if (locationDetected && connectedMap) {

      if (intervalID.current) {
        clearInterval(intervalID.current);
      }

      intervalID.current = setInterval(() => {
        fetchData();
      }, 60 * 1000)

    }
  }, [locationDetected, fetchData, connectedMap, intervalID])

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (geoPosition) => {
          const { latitude, longitude } = geoPosition.coords;
          map.setView([latitude, longitude]);
          setPosition([latitude, longitude]);
          setLocationDetected(true);
        },
        (error) => {
          console.error('Error getting user location:', error.message);
          setLocationDetected(true);
        }
      );
    } else {
      console.error('Geolocation is not supported by your browser');
      setLocationDetected(true);
    }
  }, [map]);

  useMapEvent('moveend', () => {
    const currentZoom = map.getZoom();

    if ((currentZoom <= prevZoom && currentZoom > 11) || (currentZoom > prevZoom && prevZoom === 11)) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        fetchData();
      }, 500);
    }
    if (currentZoom !== prevZoom) {
      setPrevZoom(currentZoom);
    }
  });

  return (
    <>
      {!locationDetected && <div className="detecting-location">Визначення вашого положення</div>}
      <Marker position={position} icon={user}>
        <Tooltip direction="bottom" opacity={1}>
          Ви тут.
        </Tooltip>
      </Marker>
      <ClickMapPopup />
      <Draw />
      {data?.resources?.map((item: any) => {
        if (item.resourceType === MarkerType.Multirotor || item.resourceType === MarkerType.Wing) {
          return <DroneMarker {...item} />
        }
        else if (item.resourceType === MarkerType.Circle) {
          return (
            <CircleArea {...item} />
          )
        }
        else if (item.resourceType === MarkerType.Polygon) {
          return (
            <PolygonArea {...item} />
          )
        }
        else return <></>
      })}
    </>
  );
};

export default Map;
