import { FC, useState } from 'react';
import { Polygon } from 'react-leaflet';
import { LatLngTuple, PointTuple } from 'leaflet';
import InfoPopup from './InfoPopup';

interface IPolygonArea {
  coordinates: PointTuple[];
  name: string;
  description: string;
  id: number;
  lifeTime: number;
  owner: boolean;
};

const PolygonArea: FC<IPolygonArea> = ({ coordinates, name, description, id, lifeTime, owner }) => {
  const [popupPosition, setPopupPosition] = useState<LatLngTuple | null>(null);

  const onClick = (event: any) => {
    event.originalEvent.stopPropagation();
    console.log(event);
    setPopupPosition([event.latlng.lat, event.latlng.lng])
  };

  return (
    <>
      <Polygon
        eventHandlers={{
          click: onClick
        }}
        positions={coordinates}
        pathOptions={{
          color: `${lifeTime > 0 ? '#CF6679' : '#808080'}`,
          fillColor: `${lifeTime > 0 ? '#CF6679' : '#808080'}`
        }}
      />
      <InfoPopup
        position={popupPosition}
        title={name}
        description={description}
        id={id}
        owner={owner}
        areaLifeTime={lifeTime}
      />
    </>
  )
};

export default PolygonArea;
