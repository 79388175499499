import { useState, useEffect, useCallback } from 'react';

interface RequestOptions {
  url?: string;
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  headers?: Record<string, string>;
  body?: Record<string, any>;
  getParams?: Record<string, string>;
}

interface ApiResponse<T> {
  makeRequest: (requestOptions?: RequestOptions) => void;
  data: T | null;
  error: Error | null;
  loading: boolean;
  code: number | null;
}

const useApiRequest = <T>(
  options: RequestOptions = { method: 'GET' },
  lazyLoad: boolean = false
): ApiResponse<T> => {
  const [initialOptions] = useState(options);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(!lazyLoad);
  const [code, setCode] = useState<number | null>(null);

  const executeRequest = useCallback(async (requestOptions: RequestOptions = {}) => {
    const options = { ...initialOptions, ...requestOptions };
    try {
      const { url, method, headers, body, getParams } = options;

      if (!url) {
        throw new Error('URL is required.');
      }

      const urlObj = new URL(url);
      urlObj.search = new URLSearchParams(getParams).toString();

      const requestOptions: RequestInit = {
        method: method || 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          ...headers,
        },
      };

      if (method && (method.toUpperCase() !== 'GET' && method.toUpperCase() !== 'HEAD')) {
        requestOptions.body = JSON.stringify(body);
      }

      setLoading(true);
      const response = await fetch(urlObj, requestOptions);

      setCode(response.status);

      if (!response.ok) {
        throw new Error(`Request failed with status ${response.status}`);
      }

      const result = await response.json();
      setData(result);
      setError(null);
    } catch (err) {
      setData(null);
      setError(err as Error);
    } finally {
      setLoading(false);
    }
  }, [initialOptions]);

  useEffect(() => {
    if (!lazyLoad) {
      executeRequest();
    }
  }, [initialOptions, lazyLoad, executeRequest]);

  const makeRequest = useCallback((requestOptions?: RequestOptions) => {
    executeRequest(requestOptions);
  }, [executeRequest]);

  return { makeRequest, data, error, loading, code };
};

export default useApiRequest;
