import React, { createContext, useContext, useState, ReactNode } from 'react';

interface Group {
  id: number;
  name: string;
}

export interface User {
  user: number;
  token: string;
  accessedGroups: Group[];
}

interface AuthContextProps {
  login: (userData: User) => void;
  logout: () => void;
  isAuthenticated: () => boolean;
  getToken: () => string | undefined;
  getGroups: () => Group[];
  canAdd: () => boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

const getUserSession = (): User | null => {
  const sessionUser = sessionStorage.getItem("user");
  return sessionUser ? JSON.parse(sessionUser) : null;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState(getUserSession());

  const login = (userData: User): void => {
    setUser(userData);
    sessionStorage.setItem("user", JSON.stringify(userData));
  };

  const logout = (): void => {
    setUser(null);
  };

  const isAuthenticated = (): boolean => !!user?.token;

  const getToken = (): string | undefined => user?.token;

  const getGroups = (): Group[] => user ? user?.accessedGroups : [];

  const canAdd = (): boolean => !!user?.accessedGroups?.length;

  const value: AuthContextProps = {
    login,
    logout,
    isAuthenticated,
    getToken,
    canAdd,
    getGroups
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
