import { FC, useCallback, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { User, useAuth } from '../contexts/auth';
import useApiRequest from '../hooks/useApiRequest';
import { API_URL } from '../constants';

export interface LoginFormInputs {
  email: string;
  password: string;
}

const LoginForm: FC = () => {
  const { register, handleSubmit, formState: { errors } } = useForm<LoginFormInputs>();
  const { login } = useAuth();
  const { makeRequest, data, error, loading } = useApiRequest<User>(
    {
      url: `${API_URL}/login`,
      method: 'POST',
    },
    true
  );

  const onSubmit: SubmitHandler<LoginFormInputs> = useCallback((loginData) => {
    makeRequest({ body: loginData })
  }, [makeRequest])

  useEffect(() => {
    if (data) {
      login(data)
    }
  }, [data, login])

  return (
    <form className='login-form' onSubmit={handleSubmit(onSubmit)}>
      {error &&
        <div className='login-form__error login-form__error_top'>
          Помилка авторизації. Перевірте правильність email та паролю.
        </div>
      }
      <div className='login-form__f-holder'>
        <label className='login-form__label' htmlFor="email">Email</label>
        <input className='login-form__control'
          {...register('email', {
            required: "Email є обов'язковим",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'Неправильний фомат email',
            },
          })}
          type="text"
          id="email"
        />
        {errors.email && <p className='login-form__error'>{errors.email.message}</p>}
      </div>

      <div className='login-form__f-holder'>
        <label className='login-form__label' htmlFor="password">Пароль</label>
        <input className='login-form__control'
          {...register('password', { required: "Пароль є обов'язковим" })}
          type="password"
          id="password"
        />
        {errors.password && <p className='login-form__error'>{errors.password.message}</p>}
      </div>

      <div className='login-form__s-holder'>
        <button className='login-form__submit' type="submit" disabled={loading || Object.keys(errors).length > 0}>Увійти</button>
      </div>
    </form>
  );
};

export default LoginForm;
