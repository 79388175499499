import { FC } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet'
import { useAuth } from '../contexts/auth';
import { DEFAULT_MAP_POSITION } from '../constants';
import { Routes, Route, Link } from "react-router-dom";
import Login from './Login';
import Map from '../components/Map';
import AddArea from "./AddArea";
import FormPage from './FormPage';
import AddDrone from './AddDrone';
import { DataProvider } from '../contexts/data';


const AppContent: FC = () => {
  const { isAuthenticated, canAdd } = useAuth();

  return (
    isAuthenticated() ?
      <DataProvider>
        {canAdd() && <div className="main-nav">
          <Link className="add-link" to="/add-drone">+ Дрон</Link>
          <Link className="add-link" to="/add-area">+ Область</Link>
        </div>}
        <Routes>
          <Route path="/add-area" element={<FormPage><AddArea /></FormPage>} />
          <Route path="/add-drone" element={<FormPage><AddDrone /></FormPage>} />
        </Routes>
        <MapContainer doubleClickZoom={false} className='map-container' center={DEFAULT_MAP_POSITION} zoom={13}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Map initCoords={DEFAULT_MAP_POSITION} />
        </MapContainer>
      </DataProvider> :
      <Login />
  );
}

export default AppContent;
