import { FC, createContext, useContext, useState, ReactNode, useCallback } from 'react';
import { PointTuple } from 'leaflet';

interface CoordinateBufferContextProps {
  updateCoordinates: (coordinates: PointTuple | null) => void;
  coordinates: PointTuple | PointTuple[] | null;
}

const CoordinateBufferContext = createContext<CoordinateBufferContextProps | undefined>(undefined);

interface CoordinateBufferProviderProps {
  children: ReactNode;
}

export const useCoordinateBuffer = (): CoordinateBufferContextProps => {
  const context = useContext(CoordinateBufferContext);
  if (!context) {
    throw new Error("useCoordinateBuffer should be used within CoordinateBufferProvider");
  }
  return context;
};

export const CoordinateBufferProvider: FC<CoordinateBufferProviderProps> = ({ children }) => {
  const [coordinates, setCoordinates] = useState<PointTuple | null>(null);

  const updateCoordinates = useCallback((newCoordinates: PointTuple | null) => {
    setCoordinates(newCoordinates);
  }, []);


  const contextValue: CoordinateBufferContextProps = {
    updateCoordinates,
    coordinates,
  };

  return <CoordinateBufferContext.Provider value={contextValue}>{children}</CoordinateBufferContext.Provider>
}
