import { FC } from 'react';
import { Marker } from 'react-leaflet';
import { MarkerType } from './AddArea';
import multirotor from '../markers/multirotor';
import wing from '../markers/wing';
import InfoTooltip from './InfoTooltip';

interface Drone {
  resourceType: MarkerType.Multirotor | MarkerType.Wing;
  latitude: number;
  longitude: number;
  name: string;
  description: string;
  azimuth: number;
  ours: number; 
};

enum DroneColor {
  Enemy = "#FF0000",
  Ally = "#0000FF",
}

const getIcon = (azimuth: number, ours: number, resourceType: MarkerType.Multirotor | MarkerType.Wing) => {
  const color: DroneColor = ours > 0 ? DroneColor.Ally : DroneColor.Enemy;
  const icon: Function = resourceType === MarkerType.Multirotor ? multirotor : wing;
  return icon(color, azimuth);
}

const DroneMarker: FC<Drone> = ({ resourceType, longitude, latitude, name, description, azimuth, ours }) => {

  return (
    <Marker position={[latitude, longitude]} icon={getIcon(azimuth, ours, resourceType)}>
      <InfoTooltip title={name} description={description}/>
    </Marker>
  );
};

export default DroneMarker;
