import React, { createContext, useContext, ReactNode, useCallback } from 'react';
import useApiRequest from '../hooks/useApiRequest';
import { API_URL } from '../constants';
import { latLng } from 'leaflet';
import { useAuth } from './auth';
import { useMapConnector } from './mapConnector';

interface Group {
  id: number;
  name: string;
}

export interface User {
  user: number;
  token: string;
  accessedGroups: Group[];
}

interface ServerMapData {
  areaVersion: number;
  enemyDronesVersion: number;
  resources: any[]
}

interface DataContextProps {
  fetchData: () => void;
  data: ServerMapData;
  dataLoading: boolean;
}

const DataContext = createContext<DataContextProps | undefined>(undefined);

interface DataProviderProps {
  children: ReactNode;
}

export const useData = (): DataContextProps => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within an DataProvider');
  }
  return context;
};


const calculateRadius = (bounds: L.LatLngBounds) => {
  const { lat: north, lng: east } = bounds.getNorthEast();
  const { lat: south, lng: west } = bounds.getSouthWest();

  const width = latLng(north, east).distanceTo(latLng(north, west));
  const height = latLng(north, east).distanceTo(latLng(south, east));

  return Math.max(width, height) / 2;
};


export const DataProvider: React.FC<DataProviderProps> = ({ children }) => {
  const { getToken } = useAuth();
  const { map } = useMapConnector();
  const { makeRequest, data, loading } = useApiRequest<any>(
    {
      url: `${API_URL}/resources`,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getToken()}`
      }
    },
    true
  );

  const fetchData = useCallback(() => {
    if (map) {
      const bounds = map.getBounds();
      const radius = calculateRadius(bounds);

      makeRequest({
        getParams: {
          'intersect[gps]': `${bounds.getCenter().lat};${bounds.getCenter().lng}`,
          'intersect[radius]': `${radius}`
        }
      });
    }
  }, [makeRequest, map]);

  const value: DataContextProps = {
    data,
    fetchData,
    dataLoading: loading
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

