import { FC } from 'react';
import { Popup } from 'react-leaflet';
import AreaControlForm from './AreaControlForm';
import { LatLngTuple } from 'leaflet';


interface IInfoPopup {
  id: number,
  areaLifeTime: number;
  title: string;
  owner?: boolean,
  description?: string;
  position: LatLngTuple | null;
};

const InfoPopup: FC<IInfoPopup> = ({ title, description, id, areaLifeTime, owner, position }) =>
  position && <Popup position={position}>
    <div className='info-tooltip'>
      <div className='info-tooltip__title'>{title}</div>
      {description && <div className='info-tooltip__description'>{description}</div>}

      {owner && <AreaControlForm id={id} areaLifeTime={areaLifeTime} />}
    </div>
  </Popup>

export default InfoPopup;
