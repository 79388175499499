import { divIcon } from "leaflet";

const createIcon = (color: string = '#CF6679', azimuth: number = 0) => divIcon({
	html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="transform: rotate(${azimuth}deg); stroke:${color};"><defs><style>.cls-1{fill:none; stroke-miterlimit:10;stroke-width:1.91px;}</style></defs><path class="cls-1" d="M4.84,8.18A3.34,3.34,0,1,1,8.18,4.84"/><path class="cls-1" d="M8.18,19.16a3.34,3.34,0,1,1-3.34-3.34"/><path class="cls-1" d="M15.82,4.84a3.34,3.34,0,1,1,3.34,3.34"/><path class="cls-1" d="M19.16,15.82a3.34,3.34,0,1,1-3.34,3.34"/><line class="cls-1" x1="19.64" y1="19.64" x2="14.86" y2="14.86"/><line class="cls-1" x1="9.14" y1="9.14" x2="4.36" y2="4.36"/><line class="cls-1" x1="9.14" y1="14.86" x2="4.36" y2="19.64"/><line class="cls-1" x1="19.64" y1="4.36" x2="14.86" y2="9.14"/><path class="cls-1" d="M14.86,9.14v5.72a2.86,2.86,0,1,1-5.72,0V9.14a2.86,2.86,0,1,1,5.72,0Z"/></svg>`,
	className: "user-marker",
	iconSize: [30, 30],
	iconAnchor: [15, 30],
});

export default createIcon;
