import { FC } from 'react';
import { Tooltip } from 'react-leaflet';


interface IInfoTooltip {
  title: string;
  description?: string;
};

const InfoTooltip: FC<IInfoTooltip> = ({ title, description }) =>
  <Tooltip direction="bottom" opacity={1}>
    <div className='info-tooltip'>
      <div className='info-tooltip__title'>{title}</div>
      {description && <div className='info-tooltip__description'>{description}</div>}
    </div>
  </Tooltip>;

export default InfoTooltip;
